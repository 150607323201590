const quotesEn = [
  'The creatures outside looked from pig to man, and from man to pig, and from pig to man again; but already it was impossible to say which was which.',
  "All his life he tried to be a good person. Many times, however, he failed. For after all, he was only human. He wasn't a dog.",
  'The early bird gets the worm, but the second mouse gets the cheese.',
  'I have studied many philosophers and many cats. The wisdom of cats is infinitely superior.',
  'Men have forgotten this truth, but you must not forget it. You become responsible, forever, for what you have tamed.',
  'Never mind, said Hachiko each day. Here I wait, for my friend who’s late. I will stay, just to walk beside them for one more day.',
  'We have to speak up on behalf of those who cannot speak for themselves.',
  'All the world will be your enemy, Prince with a Thousand Enemies, and whenever they catch you, they will kill you. But first they must catch you',
  "After all, what's a life, anyway? We're born, we live a little while, we die.",
  'So comes snow after fire, and even dragons have their endings.',
  'At night, when the whole world belongs to them, they parley at the river with the dogs, their higher, sharper voices full of authority and rebuke.',
  "Shake paws, count your claws, You steal mine, I'll borrow yours. Watch my whiskers, check both ears. Robber foxes have no fears.",
  'Sukses IWAG Untuk Furry Indonesia',
  'Four legs good, Two legs better.',
  'From so simple a beginning endless forms most beautiful and most wonderful have been, and are being, evolved.',
  'For the strength of the Pack is the Wolf, and the strength of the Wolf is the Pack.',
  'If you pick up a starving dog and make him prosperous it will not bite you. This is the principal difference between a dog and man.',
  'A rat in a maze is free to go anywhere, as long as it stays inside the maze.',
  'So long, and thanks for all the fish.',
  'The wolf will live with the lamb, the leopard will lie down with the goat, and the calf and the lion will be together.',
  'One must therefore be a fox to recognize traps, and a lion to frighten wolves.',
  "You can't stay in your corner of the Forest waiting for others to come to you. You have to go to them sometimes",
  'We take animals and we put our words into their mouths to talk back to us, usually about us.',
  'The human animal is a beautiful and terrible creature, capable of limitless compassion and unfathomable cruelty.',
  'What a daring dream, to combine the finest qualities of humanity with the elegance and nobility of the animal kingdom.',
  'When everything goes to hell, the people who stand by you without flinching - they are your family',
  'Sucking at something is the first step towards being sorta good at something',
];

const quotesId = [
  'Makhluk di luar memandang dari babi ke manusia, dan dari manusia ke babi, tetapi sudah tidak mungkin untuk membedakan keduanya.',
  'Sepanjang hidupnya dia berusaha menjadi orang baik. Namun berkali-kali, dia gagal. Karena bagaimanapun, dia hanya manusia. Dia bukan anjing.',
  'Burung pertama mendapatkan cacing, tetapi tikus kedua mendapatkan keju.',
  'Aku telah mempelajari banyak filsuf dan banyak kucing. Kebijaksanaan kucing jauh lebih unggul.',
  'Manusia telah melupakan kebenaran ini, tetapi kamu tidak boleh melupakannya. Kamu menjadi bertanggung jawab, selamanya, atas apa yang telah kamu jinakkan.',
  'Tidak apa-apa, kata Hachiko setiap hari. Di sini aku menunggu, untuk temanku yang terlambat. Aku akan tinggal, hanya untuk berjalan di samping dia untuk satu hari lagi.',
  'Kita harus berbicara atas nama mereka yang tidak dapat berbicara untuk diri mereka sendiri.',
  'Seluruh dunia akan menjadi musuhmu, Pangeran dengan Seribu Musuh, dan setiap kali mereka menangkapmu, mereka akan membunuhmu. Tapi pertama-tama mereka harus bisa menangkapmu',
  'Lagi pula, apa itu kehidupan? Kita lahir, kita hidup sebentar, kita mati.',
  'Jadi datanglah salju setelah api, karena bahkan naga pun memiliki akhirnya.',
  'Di malam hari, ketika seluruh dunia menjadi milik mereka, mereka berunding di sungai dengan anjing, suara mereka yang lebih tinggi dan lebih tajam penuh dengan kekuasaan dan teguran.',
  'Jabat tangannya, hitung cakarmu, kamu mencuri milikku, aku akan meminjam milikmu. Perhatikan kumisku, periksa telingaku. Rubah perampok tidak memiliki rasa takut.',
  'Sukses IWAG Untuk Furry Indonesia',
  'Empat kaki baik, dua kaki lebih baik.',
  'Dari awal yang begitu sederhana, bentuk-bentuk paling indah dan menakjubkan yang tiada habisnya telah, dan sedang, berevolusi.',
  'Karena kekuatan dari sekumpulan kawanan adalah serigalanya, dan kekuatan dari seekor serigala adalah kawanannya.',
  'Jika kamu mengambil seekor anjing yang kelaparan dan membuatnya makmur, dia tidak akan menggigitmu. Inilah perbedaan mendasar antara anjing dan manusia.',
  'Seekor tikus dalam labirin bebas pergi ke mana saja, asalkan ia tetap berada di dalam labirin.',
  'Sampai jumpa, dan terima kasih untuk semua ikannya.',
  'Sang serigala akan tinggal bersama domba, sang macan akan tidur bersama kambing, dan anak sapi dengan anak singa akan hidup bersama.',
  'Karena itu, seseorang harus menjadi rubah untuk mengenali jebakan, dan menjadi singa untuk menakuti serigala.',
  'Kamu tidak bisa tinggal di sudut Hutan sambil menunggu orang lain mendatangimu. Kadang kamu harus mendatangi mereka',
  'Kita mengambil hewan dan menaruh kata-kata kita ke dalam mulut mereka untuk berbicara kembali kepada kita, biasanya tentang kita.',
  'Manusia adalah makhluk yang indah dan mengerikan, mampu menunjukkan belas kasih yang tak terbatas dan kekejaman yang tak terbayangkan.',
  'Sungguh mimpi yang berani, untuk menggabungkan kualitas terbaik kemanusiaan dengan keanggunan kerajaan hewan.',
  'Ketika segalanya berantakan, orang-orang yang tetap bersamamu tanpa gentar - merekalah keluargamu.',
  'Menjadi buruk dalam sesuatu adalah langkah pertama untuk menjadi agak baik dalam sesuatu.',
];

export const quotes = { en: quotesEn, id: quotesId } as const;
