import { useEffect, useState } from 'react';
import { quotes } from './quotes';
import { type Locales } from '@/i18n';

export interface FlavorTextRendererProps {
  locale: Locales;
}

export default function FlavorTextRenderer({ locale }: FlavorTextRendererProps) {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    if (locale in quotes) {
      const quoteList = quotes[locale];
      const generatedQuote = quoteList[Math.floor(Math.random() * quoteList.length)];

      if (generatedQuote) {
        setQuote(generatedQuote);
      }
    }
  }, [locale]);

  if (quote) {
    return <p className="text-xs text-gray-400 md:text-center">{quote}</p>;
  }

  return <></>;
}
